// css header
header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 800;
    background-color: rgba(0,0,0,0);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    @include transition($t);
    .container {
        padding-top: 48px;
        padding-bottom: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include transition($t);
        .logo {
            display: flex;
            align-items: center;        
        }
        .menu {
            display: flex;
            align-items: center;
            position: absolute;
            left: 50%;
            @include transform(translateX(-50%));
            li {
                .link {
                    margin: 0 28px;
                    letter-spacing: .1em;
                    font-family: is;
                    opacity: .3;
                    @include text(1.2rem,is,1.8rem,$white-cl,uppercase);
                    @include transition($t);
                    cursor: pointer;
                    &:hover,
                    &.active {
                        opacity: 1;
                        @include transition($t);
                    }
                }
            }
            span {
                width: 1px;
                height: 12px;
                background-color: $white20-cl;
            }
        }
        .extra {
            display: flex;
            align-items: center;
            &_lang {
                margin-right: 22px;
                a {
                    @include text(1.2rem,is,1.8rem,$white30-cl,uppercase);
                    @include transition($t);
                    &:hover  {
                        color: $white-cl;
                        @include transition($t);
                    }
                }
            }
        }
    }
    &.scroll {
        background-color: rgba(0,0,0,.3);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        @include transition($t);
        .container {
            padding-top: 15px;
            padding-bottom: 15px;
            @include transition($t);
        }
    }
    // @include md {
    //     width: 100%;
    //     position: fixed;
    //     top: 0;
    //     z-index: 800;
    //     .container {
    //         .menu {
    //             li {
    //                 a {
    //                     margin: 0 16px;
    //                 }
    //             }
    //         }
    //     }
    // }
    @include md {
        display: none;
    }
}
// css button, title, icon, elements...
img {
    max-width: 100%;
    display: block;
}
// strong {
//     font-family: ib;
// }
a {
    font-size: $fs-body;
    text-decoration: none;
    color: $white-cl;
    outline: 0;
}

////////////////////// HEADING ///////////////////////

.heading {
    font-family: c;
    &.--h1 {
        font-size: $fs-h1;
        line-height: $lh-h1;
    }
    &.--h2 {
        font-size: $fs-h2;
        line-height: $lh-h2;
    }
    &.--h3 {
        font-size: $fs-h3;
        line-height: $lh-h3;
    }
    &.--h4 {
        font-size: $fs-h4;
        line-height: $lh-h4;
    }
    &.--h5 {
        font-family: cm;
        font-size: $fs-h5;
        line-height: $lh-h5;
    }
    &.--white {
        color: $white-cl;
    }
    &.--black {
        color: $black-cl;
    }
    // @include sm {
    //     &.--h1 {
    //         font-size: 12.7rem;
    //         line-height: 11.3rem;
    //         bottom: -10px;
    //         img {
    //             height: 107px;
    //         }
    //     }
    //     &.--h2 {
    //         font-size: 4.6rem;
    //         line-height: 5.5rem;
    //     }
    //     &.--h3 {
    //         font-size: 4.6rem;
    //         line-height: 5.5rem;
    //     }
    //     &.--h4 {
    //         font-size: 3.2rem;
    //         line-height: 4.2rem;
    //     }
    //     &.--mainheading {
    //         display: none;
    //     }
    // }
}

////////////////////// BODY TEXT ///////////////////////
.--text {
    font-family: i;
    font-size: $fs-body;
    line-height: $lh-body;
    &.bold {
        font-family: is;
    }
}
.--text-s {
    font-family: i;
    font-size: $fs-body-s;
    line-height: $lh-body-s;
    &.bold {
        font-family: i;
    }
}
.--text-xs {
    font-family: i;
    font-size: $fs-body-xs;
    line-height: $lh-body-xs;
    &.bold {
        font-family: is;
    }
}

// ////////////////////// LABEL ///////////////////////
// .--text-up {
//     font-family: ib;
//     text-transform: uppercase;
//     font-size: $fs-body-xxs;
//     line-height: $fs-body-xxs;
//     color: $black-cl;
//     &.--white {
//         color: $white-cl;
//     }
//     &.--grey {
//         color: $black40-cl;
//     }
// }

// ////////////////////// VIEWMORE ///////////////////////
// .viewmore {
//     color: $white-cl;
//     position: relative;
//     display: inline-flex;
//     align-items: center;
//     .arrows {
//         position: absolute;
//         right: -17px;
//         margin-top: 4px;
//         overflow: hidden;
//         .arrow {
//             transform: translate3d(0%, 0%, 0);
//             @include transition(0s);
//             &.clone {
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 transform: translate3d(-150%, 150%, 0);
//             }
//         }
//     }
//     &:hover {
//         .arrows {
//             .arrow {
//                 transform: translate3d(150%, -150%, 0);
//                 @include transition(transform cubic-bezier(0.4, 0, 0.1, 1) 0.6s);
//                 &.clone {
//                     transform: translate3d(0%, 0%, 0);
//                 }
//             }
//         }
//     }
// }

/////////////////////////// CURSOR MORE ///////////////////////////
// .cursor {
//     @include size(92px);
//     @include br(50%);
//     position: absolute;
//     pointer-events: none;
//     @include mid(true);
//     color: $white-cl;
//     @include transition($t opacity);
//     will-change: transform;
//     z-index: 900;
//     &__hover {
//         @include size(100%);
//         @include transform(scale(0));
//         position: absolute;
//         &:after {
//             content: "view";
//             color: $white-cl;
//             @include mid;
//             z-index: 10;
//             font-family: ib;
//             font-size: $fs-body-s;
//             mix-blend-mode: difference;
//         }
//         &-inner {
//             @include size(78px);
//             background: $white-cl;
//             @include transition($t background);
//             @include br(50%);
//             @include mid;
//         }
//         &-circle {
//             width: 100%;
//             height: 100%;
//             background: url(../img/cursor-circle.svg) no-repeat center;
//             @include br(50%);
//             @include mid;
//         }
//         &.--hv-linkedin {
//             &:after {
//                 content: "linkedin";
//             }
//         }
//         &.--hv-scroll {
//             &:after {
//                 content: "scroll";
//             }
//         }
//         &.--hv-drag {
//             &:after {
//                 content: "drag";
//             }
//             .cursor__hover-circle {
//                 background: url(../img/cursor-circle-black.svg) no-repeat center;
//             }
//             .cursor__hover-inner {
//                 background-color: $black-cl;
//                 @include transition($t background);
//             }
//         }
//     }
//     &__main {
//         @include size(36px);
//         opacity: 1;
//         @include transition($t opacity);
//         mix-blend-mode: difference;
//         svg {
//             fill: none;
//         }
//         &-progress {
//             position: relative;
//             z-index: 10;

//             circle {
//                 @include size(100%);
//                 position: absolute;
//                 top: 0;
//                 left: 0;

//                 fill: none;
//                 stroke: rgb(112, 112, 112);
//                 stroke-width: 3;
//                 stroke-dasharray: 200;
//                 stroke-dashoffset: 200;

//                 mix-blend-mode: exclusion;
//             }
//         }
//         &-outline {
//             @include size(36px);
//             @include mid;
//             z-index: 1;
//             circle {
//                 @include size(100%);
//                 stroke: rgba($color: #3f3f3f, $alpha: 0.24);
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 stroke-width: 3;
//                 stroke-dashoffset: 200;
//             }
//         }
//     }
//     &.active {
//         opacity: 1;
//         @include transition($t opacity);
//         .cursor__main {
//             opacity: 0;
//             @include transition($t opacity);
//         }
//     }
//     @include sm {
//         display: none;
//     }
// }

//////////////////////////// UNDERLINE HOVER ////////////////////////
.--underline {
    position: relative;
    &:after {
        content: "";
        width: 100%;
        height: 1px;
        background: $gold-cl;
        position: absolute;
        bottom: -4px;
        left: 0;
        mix-blend-mode: difference;
        @include transform(scaleX(0));
        @include transform-origin(right);
        @include transition($t transform $cubic);
    }
    &:hover,
    &.active {
        &:after {
            @include transform(scaleX(1));
            @include transform-origin(left);
            @include transition($t transform $cubic);
        }
    }
}
.--underline2 {
    position: relative;
    span {
        position: relative;
        display: inline-block;
        &:after {
            content: "";
            width: 100%;
            height: 1px;
            background: $white-cl;
            position: absolute;
            bottom: 0px;
            left: 0;
            mix-blend-mode: difference;
            @include transform(scaleX(0));
            @include transform-origin(right);
            @include transition($t transform $cubic);
        }
    }
    &:hover,
    &.active {
        span {
            &:after {
                @include transform(scaleX(1));
                @include transform-origin(left);
                @include transition($t transform $cubic);
            }
        }
    }
}
// ////////////////// FADE IN //////////////////
// .--fadeIn {
//     --duration: 0.8s;
//     --delay: var(--delay);
//     opacity: 0;
//     @include transform(translateY(50px));
//     @include transition(var(--duration) var(--delay));
//     will-change: transform, opacity;
//     .is-inview {
//         opacity: 1;
//         @include transform(translateY(0px));
//         @include transition(var(--duration) var(--delay));
//     }
//     &.is-inview {
//         opacity: 1;
//         @include transform(translateY(0px));
//         @include transition(var(--duration) var(--delay));
//     }
//     @include sm {
//         opacity: 1;
//         @include transform(translateY(0px));
//         @include transition(0s);
//     }
// }
// .--staggerText {
//     .char {
//         opacity: 0;
//         @include transform(translateY(40%));
//         @include transition(0.7s);
//         @include transition-delay(calc(var(--char-index) * 0.02s));
//         will-change: transform, opacity;
//     }
//     &.is-inview {
//         .char {
//             opacity: 1;
//             @include transform(translateY(0%));
//             @include transition(0.7s);
//             @include transition-delay(calc(var(--char-index) * 0.02s));
//         }
//     }
//     @include sm {
//         .char {
//             opacity: 1;
//             @include transform(translateY(0px));
//             @include transition(0);
//         }
//     }
// }
// .--expandLine {
//     @include transform(scaleX(0));
//     @include transform-origin(right);
//     @include transition(0.5s var(--delay) transform $cubic);
//     &.is-inview {
//         @include transform(scaleX(1));
//         @include transform-origin(left);
//         @include transition(0.5s var(--delay) transform $cubic);
//     }
//     @include sm {
//         @include transform(scaleX(1));
//     }
// }

// .btnsubmit {
//     @include size(140px);
//     @include br(50%);
//     position: relative;
//     cursor: pointer;
//     @include transition(transform cubic-bezier(0.4, 0, 0.1, 1) 0.6s);
//     mix-blend-mode: difference;
//     background: none;
//     padding: 0;
//     -webkit-clip-path: circle(50%);
//     clip-path: circle(50%);
//     border: 0;
//     .arrows {
//         @include mid;
//         @include size(38px);
//         overflow: hidden;
//         .arrow {
//             @include size(100%);
//             transform: translate3d(0%, 0%, 0);
//             @include transition(0s);
//             &.clone {
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 transform: translate3d(-150%, 150%, 0);
//             }
//         }
//     }
//     .progress {
//         position: absolute;
//         @include size(120px);
//         top: calc(50% - 60px);
//         left: calc(50% - 60px);
//         transition: transform 0.6s cubic-bezier(0.7, 0, 0.3, 1);
//         &__circle,
//         &__path {
//             fill: none;
//             stroke: $black20-cl;
//             stroke-width: 0.6px;
//         }
//         &__path {
//             stroke: $black60-cl;
//             stroke-dasharray: 1;
//             stroke-dashoffset: 1;
//             transition: stroke-dashoffset 0.6s cubic-bezier(0.7, 0, 0.3, 1);
//         }
//     }

//     &.--white {
//         @include transform(rotate(90deg));
//         @include transform-origin(center);
//         .arrows {
//             svg {
//                 * {
//                     stroke: $white-cl;
//                 }
//             }
//         }
//         .progress {
//             &__circle,
//             &__path {
//                 stroke: $black60-cl;
//             }
//             &__path {
//                 stroke: $black05-cl;
//             }
//         }
//     }
//     &.--arrowdown {
//         @include transform(rotate(90deg));
//     }
//     &.--submitform {
//         position: absolute;
//         bottom: -44px;
//         right: 0;
//     }
//     &:hover {
//         @include transition($t);
//         .progress {
//             transform: scale3d(1.2, 1.2, 1);
//             &__path {
//                 stroke-dashoffset: 0;
//             }
//         }
//         .arrows {
//             .arrow {
//                 transform: translate3d(150%, -150%, 0);
//                 @include transition(transform cubic-bezier(0.4, 0, 0.1, 1) 0.6s);
//                 &.clone {
//                     transform: translate3d(0%, 0%, 0);
//                 }
//             }
//         }
//     }
//     @include sm {
//         margin: 40px auto 20px auto;
//         @include size(120px);
//         border-width: 2px;
//         .arrows {
//             @include size(28px);
//         }
//         .progress {
//             position: absolute;
//             @include size(100px);
//             top: calc(50% - 50px);
//             left: calc(50% - 50px);
//         }
//         &.--submitform {
//             display: table;
//             position: relative;
//             bottom: initial;
//             right: initial;
//             margin: 0 auto;
//         }
//     }
// }

.scbreadcrumbblogdetail {
    .breadcrumb {   
        padding-top: 210px;
        grid-column: 3/11;
        margin-bottom: 32px;
        ul {
            @include mid(true);
            li {
                margin: 0 12px;
                a {
                    @include text(1.2rem, is, 1.8rem, $white30-cl, uppercase);
                    @include transition($t);
                    &:hover {
                        color: $white-cl;
                        @include transition($t);
                    }
                }
            }
        }
    }
    @include md {
        .breadcrumb {
            grid-column: 1/13;
        }
    }
    @include sm {
        .breadcrumb {
            padding-top: 110px;
            grid-column: 1/7;
        }
    }
}

.scarticleblogdetail {
    .article-title {
        grid-column: 3/11;
        text-align: center;
        color: $gold-cl;
        margin-bottom: 64px;
    }
    .article-thumbnail {
        grid-column: 2/12;
        margin-bottom: 62px;
    }
    .article-content {
        grid-column: 4/10;
        padding-bottom: 69px;
        border-bottom: solid 1px $gold30-cl;
        .--text {
            color: $white60-cl;
            margin-bottom: 26px;
            &.--last {
                margin-bottom: 45px;
            }
        }
        &-img {
            margin: 72px 0 60px;
            &.--last {
                margin: 0px;
            }
        }
        .--h5 {
            color: $white60-cl;
            margin-bottom: 24px;
            text-transform: uppercase;
            &.--sub {
                margin-bottom: 32px;
            }
        }
        .--text.bold {
            color: $white60-cl;
            text-transform: uppercase;
            margin-bottom: 16px;
        }
        .article-content-list {
            margin-bottom: 42px;
            li {
                margin-left: 20.5px;
                list-style-image: url('../img/ic-check.svg');
                .--text {
                    padding-left: 14px;
                    
                }
            }
        }
    }
    @include md {
        .article-title {
            grid-column: 2/12;
        }
        .article-thumbnail {
            grid-column: 1/13;
        }
        .article-content {
            grid-column: 3/11;
        } 
    }
    @include sm {
        .article-title {
            grid-column: 2/6;
            font-size: 4.2rem;
            line-height: 4.8rem;
        }
        .article-thumbnail {
            grid-column: 1/7;
        }
        .article-content {
            grid-column: 2/6;
        } 
    }
    @include xs {
        .article-title {
            grid-column: 1/7;
        }
        .article-thumbnail {
            grid-column: 1/7;
        }
        .article-content {
            grid-column: 1/7;
        } 
    }
}

.screlateblogdetail {
    padding-top: 110px;
    padding-bottom: 160px;
    &__title {
        grid-column: 4/10;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 42px;
        .--h4 {
            text-transform: uppercase;
            color: $gold-cl;
        }
    }
    &__content {
        grid-column: 4/10;
        @include col(2);
        column-gap: 32px;
        .blog-item {
            width: 100%;
        }
    }
    .--mobile {
        display: none;
    }
    @include md {
        &__title {
            grid-column: 3/11;
        }
        &__content {
            grid-column: 3/11;
            .blog-item {
                width: 100%;
            }
        }
    }
    @include sm {
        padding-top: 80px;
        padding-bottom: 110px;
        &__title {
            justify-content: center;
            grid-column: 2/6;
            .--h4 {
                text-align: center;
            }
            .button {
                display: none;
            }
        }
        &__content {
            grid-column: 2/6;
            display: block;
            .blog-item {
                width: 100%;
            }
        }
        .--mobile {
            display: block;
            text-align: center;
            grid-column: 3/5;
        }
    }
    @include xs {
        &__title {
            grid-column: 1/7;
        }
        &__content {
            grid-column: 1/7;
            .blog-item {
                width: 100%;
            }
        }
        .--mobile {
            grid-column: 1/7;
        }
    }
}
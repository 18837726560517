@-ms-viewport {
    width: device-width;
}
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    user-select: none;
    -webkit-user-select: none;
}
html {
    font-size: 62.5%;
}

/*layout variables*/
$pd-top: 136px;

.wrapper {
    &__background {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1;
        overflow-x: hidden;
        .grid-line {
            @include size(100%);
            position: absolute;
            top: 0;
            left: 50%;
            @include transform(translateX(-50%));
            width: 2750px;
            display: flex;
            margin: 0 auto;
            justify-content: space-between;
            .grid {
                display: block;
                @include size(1px,100%);
                background-color: $white05-cl;
                opacity: .5;
            }
        }
    }
}

body {
    // disable zoom on iOS 14
    touch-action: pan-y;
    //
    width: 100%;
    margin: auto;
    @include text($fs-body, $font-main, $lh-body, $white-cl, none);
    background: $background-cl;
    position: relative;
    // @include customscroll();
    // .scrollmain {
    //     width: 100%;
    //     height: 100%;
    //     margin: auto;
    //     overflow: hidden;
    //     overflow-y: initial !important;
    //     will-change: transform;
    //     position: relative;
    // }

    .container {
        width: 100%;
        padding-right: 48px;
        padding-left: 48px;
        margin-right: auto;
        margin-left: auto;
        max-width: 1600px;
        &.--grid {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-auto-rows: auto;
            column-gap: 32px;
        }
        @include sm {
            padding-right: 20px;
            padding-left: 20px;
            &.--grid {
                grid-template-columns: repeat(6,1fr);
                column-gap: 20px;
            }
        }
    }
    // .container-fluid {
    //     width: 100%;
    //     padding-right: 30px;
    //     padding-left: 30px;
    //     margin-right: auto;
    //     margin-left: auto;
    //     @include xs {
    //         padding-right: 15px;
    //         padding-left: 15px;
    //     }
    // }
    // .row {
    //     display: -webkit-box;
    //     display: -ms-flexbox;
    //     display: flex;
    //     -ms-flex-wrap: wrap;
    //     flex-wrap: wrap;
    //     margin-right: -15px;
    //     margin-left: -15px;
    // }
    // .no-gutters {
    //     margin-right: 0;
    //     margin-left: 0;
    // }
    // .no-gap > .col,
    // .no-gap > [class*="col-"] {
    //     padding-right: 0;
    //     padding-left: 0;
    // }
}

/*primary colors*/

$gold-cl: rgba(147, 129, 93, 1);
$gold90-cl: rgba(147, 129, 93, 0.9);
$gold80-cl: rgba(147, 129, 93, 0.8);
$gold70-cl: rgba(147, 129, 93, 0.7);
$gold60-cl: rgba(147, 129, 93, 0.6);
$gold50-cl: rgba(147, 129, 93, 0.5);
$gold40-cl: rgba(147, 129, 93, 0.4);
$gold30-cl: rgba(147, 129, 93, 0.3);
$gold20-cl: rgba(147, 129, 93, 0.2);
$gold10-cl: rgba(147, 129, 93, 0.1);
$gold05-cl: rgba(147, 129, 93, 0.05);

/*neutral colors*/
$white-cl: #ffffff;
$white90-cl: rgba(255, 255, 255, 0.9);
$white80-cl: rgba(255, 255, 255, 0.8);
$white70-cl: rgba(255, 255, 255, 0.7);
$white60-cl: rgba(255, 255, 255, 0.6);
$white50-cl: rgba(255, 255, 255, 0.5);
$white40-cl: rgba(255, 255, 255, 0.4);
$white30-cl: rgba(255, 255, 255, 0.3);
$white20-cl: rgba(255, 255, 255, 0.2);
$white10-cl: rgba(255, 255, 255, 0.1);
$white05-cl: rgba(255, 255, 255, 0.05);

$black-cl: #181818;
$black90-cl: rgba(24, 24, 24, 0.9);
$black80-cl: rgba(24, 24, 24, 0.8);
$black70-cl: rgba(24, 24, 24, 0.7);
$black60-cl: rgba(24, 24, 24, 0.6);
$black50-cl: rgba(24, 24, 24, 0.5);
$black40-cl: rgba(24, 24, 24, 0.4);
$black30-cl: rgba(24, 24, 24, 0.3);
$black20-cl: rgba(24, 24, 24, 0.2);
$black10-cl: rgba(24, 24, 24, 0.1);
$black05-cl: rgba(24, 24, 24, 0.05);

// background colors
$background-cl: #131313;

/*headings typo*/
$fs-h1: 9.6rem;
$lh-h1: 9.6rem;
$fs-h2: 7.2rem;
$lh-h2: 7.8rem;
$fs-h3: 5.2rem;
$lh-h3: 6rem;
$fs-h4: 4rem;
$lh-h4: 4.4rem;
$fs-h5: 2rem;
$lh-h5: 2.2rem;

$fs-body: 1.6rem;
$lh-body: 2.6rem;

$fs-body-s: 1.4rem;
$lh-body-s: 2.2rem;

$fs-body-xs: 1.2rem;
$lh-body-xs: 2rem;

// timing transition
$t: 0.5s;
$cubic: cubic-bezier(0.59, 0.23, 0.22, 0.96);

/*font families*/
$font-main: i;

//============================================================//

/*BOXED LAYOUT*/
$container-max-width: 1600px;
$container-width: 1536px; // tùy thuộc vào container của bản thiết kế mà chỉnh sửa cho hợp lý

/*GRID - media queries breakpoints*/
$xxs-min: 576px; // CÓ THỂ TÙY CHỈNH
$xs-min: 768px; // KHÔNG THAY ĐỔI
$sm-min: 992px; // KHÔNG THAY ĐỔI
$md-min: 1280px; // KHÔNG THAY ĐỔI
$lg-min: 1450px; // CÓ THỂ TÙY CHỈNH

$xxs-max: ($xxs-min - 1); // Small Mobile
$xs-max: ($xs-min - 1); // Mobile
$sm-max: ($sm-min - 1); // Tablet
$md-max: ($md-min - 1); // Desktop
$lg-max: ($lg-min - 1); // Large Desktop

.footer {
    padding-top: 110px;
    background-color: $black-cl;
    &__logo {
        grid-column: 2/5;
        img {
            margin-top: 12px;
        }
    }
    &__tagline {
        grid-column: 5/13;
        .--h3 {
            width: 45%;
            min-width: 539px;
            color: $gold-cl;
            text-transform: uppercase;
            margin-bottom: 53px;
        }
        span {
            @include size(100%, 1px);
            display: block;
            background: $white10-cl;
        }
    }
    &__contact {
        grid-column: 2/13;
        @include col(11);
        column-gap: 32px;
        padding: 73px 0 22px;
        .contact-block {
            grid-column: 1/4;
            .--text-xs {
                color: $white40-cl;
                line-height: 2.2rem;
                margin-bottom: 12px;
            }
            .--h5 {
                width: 68%;
                min-width: 195px;
                color: $white-cl;
                margin-bottom: 16px;                
            }
            .--text-s {
                width: 68%;
                min-width: 237px;
                color: $white40-cl;
                margin-top: 2.2rem;
                span {
                    font-family: ib;
                }
                a {
                    color: $gold-cl;
                }
            }
            &:nth-child(2) {
                grid-column: 4/7;
            }
            &:nth-child(3) {
                grid-column: 7/10;
            }
        }
        .contact-info {
            grid-column: 10/12;
            .--text-xs {
                color: $white40-cl;
                margin-bottom: 7px;
                display: block;
            }
            a.--text {
                display: inline-block;
                color: $gold-cl;
                margin-bottom: 32px;
                align-items: center;
            }
            span {
                display: flex;
                .ic-footer {
                    display: inline-block;
                    @include size(18px);
                    border-radius: 50%;
                    background-color: $gold-cl;
                    position: relative;
                    margin-top: 5px;
                    margin-right: 8px;
                    img {
                        @include mid;
                    }
                }
            }
        }
    }
    &__copy {
        grid-column: 5/13;
        padding: 54px 0 49px;
        .--text-xs {
            color: $white50-cl;
        }
    }
    @include md {
        padding-top: 110px;
        &__logo {
            grid-column: 1/2;
        }
        &__tagline {
            grid-column: 2/13;
            .--h3 {
                width: 80%;
                min-width: auto;
            }
        }
        &__contact {
            grid-column: 2/13;        
        }
        &__copy {
            grid-column: 2/13;
        }
    }
    @include sm {
        padding-top: 80px;
        &__logo {
            grid-column: 1/2;
        }
        &__tagline {
            grid-column: 2/7;
            .--h3 {
                width: 100%;
                min-width: auto;
                font-size: 3.2rem;
                line-height: 4rem;
                margin-bottom: 32px;
            }
            span {
                @include size(100%, 1px);
                display: block;
                background: $white10-cl;
            }
        }
        &__contact {
            grid-column: 1/7;
            @include col(6);
            column-gap: 20px;
            padding: 32px 0 22px;
            .contact-block {
                grid-column: 2/7;
                margin-bottom: 48px;
                .--text-xs {
                    margin-bottom: 12px;
                }
                .--h5 {
                    width: 85%;
                    min-width: auto;
                    margin-bottom: 14px;                
                }
                .--text-s {
                    width: 90%;
                    min-width: auto;
                    margin-top: 1.4rem;
                }
                &:nth-child(2) {
                    grid-column: 2/7;
                }
                &:nth-child(3) {
                    grid-column: 2/7;
                    margin-bottom: 56px;
                }
            }
            .contact-info {
                grid-column: 2/7;
                a.--text {
                    margin-bottom: 18px;
                }
            }
        }
        &__copy {
            grid-column: 2/7;
            padding: 24px 0 24px;

        }
    }
}
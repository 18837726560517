.scherohome {
    padding-top: $pd-top ;
    &__title {
        grid-column: 3/11;
        text-align: center;
        .logo {
            display: inline-block;
            margin: 4px 0 46px;
        }
        &-inner {
            .sub-title {
                @include text(1.6rem,cm,1.9rem,$white-cl,uppercase);
                letter-spacing: .6em;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 32px;
                span {
                    @include size(60px,1px);
                    background-color: #DBC283;
                    opacity: .3;
                    margin: 0 37px;
                    display: inline-block;
                }
            }
            .heading {
                font-family: c;
                text-transform: uppercase;
                color: $gold-cl;
                margin-bottom: 66px;
            }
        }
    }
    &__video {
        grid-column: 2/12;
        height: auto;
        margin-bottom: 120px;
        position: relative;
        video {
            width: 100%;
            height: auto;
        }
        .sound-icon {
            position: absolute;
            right: 24px;
            bottom: 32px;
        }
    }
    @include md {
        &__title {
            grid-column: 1/13;
        }
        &__video {
            grid-column: 1/13;
            width: 100%;
            height: auto;
            margin-bottom: 80px;
            iframe {
                width: 100%;
                height: auto;
            }
        }
    }
    @include sm {
        padding-top: 140px;
        &__title {
            grid-column: 1/7;
            .logo {
                margin: 0 0 29px;
            }
            &-inner {
                .sub-title {
                    margin-bottom: 29px;
                    span {
                        @include size(60px,1px);
                        background-color: #DBC283;
                        opacity: .3;
                        margin: 0 36px 0 0;
                        display: inline-block;
                        &:nth-child(2) {
                            margin: 0 0 0 36px;
                        }
                    }
                }
                .heading {
                    font-size: 4.2rem;
                    line-height: 4.8rem;
                    margin-bottom: 43px;
                }
            }
        }
        &__video {
            grid-column: 1/7;
            width: 100%;
            height: auto;
            margin: 0 auto 40px;
        }
    }
    @include xs {
        &__video {
            width: calc(100% + 40px);
            height: auto;
            margin: 0 -20px 40px;
        }
    }
}

.scabouthome {
    &__sub-text {
        grid-column: 4/10;
        @include text(2.4rem,il,4rem,$white60-cl,none);
        text-align: center;
        margin-bottom: 110px;
        margin-left: auto;
        margin-right: auto;
        max-width: 660px;
    }
    &__content {
        grid-column: 1/13;
        @include col(12);
        column-gap: 32px;
        &-inner {
            grid-column: 2/6;
            .box {
                @include size(calc(50%+11px), auto);
                display: block;
                border: solid 1px $gold40-cl;
                position: relative;
                .text {
                    margin: 43px 0 41px 90px;
                    @include size(calc(200%-64px),auto);
                    padding: 22px 0 27px;
                    background-color: $background-cl;
                    .--h5 {
                        font-family: cm;
                        margin-bottom: 14px;
                        text-transform: uppercase;
                    }
                    .--h2 {
                        font-family: c;
                        margin-bottom: 22px;
                        text-transform: uppercase;
                        color: $gold-cl;
                    }
                    .--text {
                        color: $white60-cl;
                        margin-bottom: 15px;
                        &.--last {
                            margin-bottom: 32px;
                        }
                    }
                    .button {
                        display: inline-block;
                    }
                }
            }            
        }
        &-items {
            grid-column: 6/12;
            @include col(3);
            column-gap: 32px;
            row-gap: 32px;
            margin-bottom: 60px;
            .about_item {
                grid-column: 3/4;
                grid-row: 1/2;
                width: 100%;
                padding-bottom: 100%;
                position: relative;
                cursor: pointer;
                &-image {
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    bottom: -1px;
                    right: -1px;
                    overflow: hidden;
                    img {
                        @include midimg;
                    }
                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background-color: #000;
                        opacity: .8;
                        overflow: hidden;
                        @include transition($t);
                    }
                }
                .item-line {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    bottom: 10px;
                    right: 10px;
                    border: solid 1px $gold20-cl;
                    @include transition($t);
                }
                p {
                    position: absolute;
                    left: 27px;
                    bottom: 27px;
                    @include text(2rem,cs,auto,$white-cl,uppercase)
                }
                &.--second {
                    grid-column: 2/3;
                    grid-row: 2/3;
                }
                &.--third {
                    grid-column: 1/2;
                    grid-row: 3/4;
                }
                &.--fourth {
                    grid-row: 3/4;
                }
                &:hover {
                    .overlay {
                        background-color: #332C1D;
                        opacity: .75;
                        @include transition($t);
                    }
                    .item-line {
                        border: solid 1px $gold50-cl;
                        top: -10px;
                        left: -10px;
                        bottom: -10px;
                        right: -10px;
                        @include transition($t);
                    }
                }
                
            }
        }
    }
    @include lg {
        &__content {
            &-inner {
                .box {
                    .text {
                        @include size(210%,auto);
                    }
                }                        
            }
            &-items {
                margin-top: 200px;
            }
        }
    }
    @include md {
        &__sub-text {
            grid-column: 2/12;
        }
        &__content {
            &-inner {
                grid-column: 1/7;
                .box {
                    .text {
                        @include size(190%,auto);
                    }
                }            
            }
            &-items {
                grid-column: 4/13;
                margin-top: -215px;
            }
        }
    }
    @include sm {
        &__sub-text {
            grid-column: 1/7;
        }
        &__content {
            grid-column: 1/7;
            @include col(6);
            column-gap: 20px;
            &-inner {
                grid-column: 1/7;
                margin-bottom: 65px;
                .box {
                    @include size(calc(33.33%), auto);
                    .text {
                        margin: 43px 0 41px 16%;
                        width: 250%;
                        padding: 22px 0 26px;
                        .--h2 {
                            font-size: 4.2rem;
                            line-height: 4.8rem;
                            margin-bottom: 25px;
                        }
                    }
                }           
            }
            &-items {
                grid-column: 2/7;
                margin-top: -40px;
            }
        }
    }
    @include xs {
        &__sub-text {
            grid-column: 1/7;
            @include text(2rem,il,3.4rem,$white60-cl,none);
            margin-bottom: 86px;
            margin-left: 17px;
            margin-right: 17px;
        }
        &__content {
            grid-column: 1/7;
            @include col(6);
            column-gap: 20px;
            &-inner {
                grid-column: 1/7;
                margin-bottom: 65px;
                .box {
                    @include size(calc(66.66%), auto);
                    .text {
                        margin: 43px 0 41px 26%;
                        width: 125%;
                        padding: 22px 0 26px;
                        .--h2 {
                            font-size: 4.2rem;
                            line-height: 4.8rem;
                            margin-bottom: 25px;
                        }
                    }
                }            
            }
            &-items {
                grid-column: 1/7;
                @include col(2);
                column-gap: 20px;
                row-gap: 24px;
                margin-bottom: 0px;
                margin-top: 0px;
                .about_item {
                    grid-column: 1/2;
                    grid-row: 1/2;
                    p {
                        position: absolute;
                        left: 22px;
                        bottom: 26px;
                        @include text(1.8rem,cs,auto,$white-cl,uppercase)
                    }
                    &.--second {
                        grid-column: 2/3;
                        grid-row: 1/2;
                    }
                    &.--third {
                        grid-column: 1/2;
                        grid-row: 2/3;
                    }
                    &.--fourth {
                        grid-column: 2/3;
                        grid-row: 2/3;
                    }
                }
            }
        }
    }
}

.scproducthome {
    padding-top: 160px;
    overflow: visible;
    &__logo {
        grid-column: 1/13;
        margin: 0 auto 42px;
    }
    &__title {
        grid-column: 4/10;
        text-align: center;
        .--h2 {
            color: $gold-cl;
            text-transform: uppercase;
            br {
                display: none;
            }
        }
    }
    &__product {
        grid-column: 1/13;
        width: 100%;
        height: 664px;        
        margin: 111px auto 160px;
        position: relative;
        &-wrapper {
            @include fullscreen(false);

            .product-bottle {
                @include fullscreen(false);
                &-inner {
                    margin: 0 auto;
                    width: 16%;
                    height: 100%;
                    position: relative;
                    video {
                        @include midimg;
                    }
                    img {
                        @include midimg;
                    }
                }
            }
            .product-pills {
                @include fullscreen(false);
                &-inner {
                    margin: -100px auto;
                    width: calc(100% + 200px);
                    height: 100%;
                    position: relative;
                    display: flex;
                    .pills-box {
                        @include size(50%, 100%);
                        position: relative;
                        .pill {
                            @include size(118px,auto);
                            position: absolute;
                            top: 0px;
                            right: 78%;
                            &:nth-child(2) {
                                top: 50%;
                                right: 33%;
                                @include size(82px,auto);
                                @include filter(blur(4px));
                                @include transform(rotateZ(-22deg));
                            }
                            &:nth-child(3) {
                                top: 77%;
                                right: 52%;
                                @include size(123px,auto);
                                @include filter(blur(14px));
                                @include transform(rotateZ(65deg));
                            }
                            &:nth-child(4) {
                                top: 93%;
                                right: 92%;
                                @include size(190px,auto);
                                @include transform(rotateZ(-43deg));
                            }
                        }
                    }
                    .pills-box-right {
                        @include size(50%, 100%);
                        position: relative;
                        .pill {
                            position: absolute;
                            top: 70%;
                            left: 0%;
                            @include size(100px,auto);
                            @include filter(blur(18px));
                            @include transform(rotateZ(-200deg));
                            &:nth-child(2) {
                                top: 58%;
                                left: 26%;
                                @include size(100px,auto);
                                @include filter(blur(5px));
                                @include transform(rotateZ(89deg));
                            }
                            &:nth-child(3) {
                                top: 17%;
                                left: 53%;
                                @include size(110px,auto);
                                @include filter(blur(0px));
                                @include transform(rotateZ(23deg));
                            }
                            &:nth-child(4) {
                                top: 74%;
                                left: 75%;
                                @include size(185px,auto);
                                @include filter(blur(0px));
                                @include transform(rotateZ(79deg));
                            }
                        }
                    }
                }
            }
        }
        .product-features {
            @include fullscreen(false);
            display: flex;
            .features-box {
                text-align: right;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 26px 188px 26px 0;
                width: 50%;

                .feature-item {
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;
                    &:nth-child(2) {
                        margin-right: 92px;
                    }
                    .item-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include size(120px);
                        border-radius: 50%;
                        border: solid 1px $white10-cl;
                    }
                    .item-text {
                        margin-right: 24px;
                        max-width: 61%;
                        .--h5 {
                            color: $gold-cl;
                            text-transform: uppercase;
                            margin-bottom: 12px;
                        }
                        .--text {
                            color: $white60-cl;
                            .bold {
                                font-family: is;
                            }
                        }
                    }
                }
                &.--right {
                    text-align: left;
                    padding: 26px 0 26px 188px;
                    .feature-item {
                        flex-direction: row;
                        .item-text {
                            margin-right: 0;
                            margin-left: 24px;
                        }
                        &:nth-child(2) {
                            margin-right: 0;
                            margin-left: 92px;
                        }
                    }
                }
            }
        }
        .product-background {
            position: relative;
            @include size(100%);
            .bg-circle {
                @include mid;
                .anim-rotate {
                    @include animation(rotating 36s linear infinite);
                }
                @keyframes rotating {
                    from {
                        @include transform(rotate(0deg));
                    }
                    to {
                        @include transform(rotate(360deg));
                    }
                }
            }
            .bg-blur {
                @include mid;
                width: 28%;
                padding-bottom: 28%;                
                .bg-blur-img {
                    min-width: 200%;
                    min-height: 200%;
                    object-fit: cover;
                    position: absolute;
                    @include mid;
                }
            }
        }
    }
    &__moreinfo {
        grid-column: 5/9;
        @include mid(true);
        padding-bottom: 168px;
        .--product {
            display: inline-block;
            padding: 19px 26px;
        }
    }
    @media screen and (max-width: 1560px) {
		&__product {
            .product-features {
                .features-box {
                    .feature-item {
                        .item-text {
                            .--text br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
	}
    @include md {
        &__title {
            grid-column: 2/12;
            .--h2 {
                br {
                    display: inline;
                }
            }
        }
        &__product {
            grid-column: 1/13;
            height: 664px;        
            margin: 180px auto 100px;
            .product-features {
                .features-box {
                    padding: 70px 236px 70px 0;
                    width: 50%;
                    .feature-item {
                        &:nth-child(2) {
                            margin-right: 0;
                        }
                        .item-icon {
                            display: none;
                        }
                        .item-text {
                            max-width: 100%;
                        }
                    }
                    &.--right {
                        padding: 70px 0 70px 236px;
                        .feature-item {
                            &:nth-child(2) {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
            .product-background {
                .bg-blur {              
                    .bg-blur-img {
                        min-width: 300%;
                        min-height: 300%;
                    }
                }
            }
        } 
    }
    @include sm {
        &__logo {
            grid-column: 1/7;
            margin: 0 auto 32px;
        }
        &__title {
            grid-column: 1/7;
            margin-bottom: 80px;
            .--h2 {
                font-size: 4.2rem;
                line-height: 5rem;
                padding: 0 20px;
                br {
                    display: none;
                }
            }
        }
        &__product {
            grid-column: 1/7;
            width: 100%;
            height: auto;        
            margin: 60px auto 100px;
            position: relative;
            &-wrapper {
                position: relative;
                height: auto;
                .product-bottle {
                    &-inner {
                        width: 30%;
                        max-width: 245px;
                        video {
                            max-width: 130%;
                            min-height: auto;
                            min-width: auto;
                            object-fit: cover;
                            top: 50%;
                        }
                        img {
                            max-width: 130%;
                            min-height: auto;
                            min-width: auto;
                            object-fit: cover;
                            top: 50%;
                        }
                    }
                }
                .product-pills {
                    &-inner {
                        margin: -60px auto;
                        width: 100%;
                        .pills-box {
                            @include transform(scale3d(.8,.8,.8) translateY(5%));
                            .pill {
                                &:nth-child(2) {
                                    top: 43%;
                                    left: 57%;
                                }
                            }
                        }
                        .pills-box-right {
                            @include transform(scale3d(.8,.8,.8) translateY(5%));
                            .pill {
                                left: 13%;
                                &:nth-child(2) {
                                    left: 48%;
                                }
                                &:nth-child(3) {
                                    left: 78%;
                                    top: -8%;
                                    @include transform(rotateZ(47deg));
                                }
                                &:nth-child(4) {
                                    left: 82%;
                                    top: 94%;
                                }
                            }
                        }
                    }
                }
            }
            .product-features {
                @include fullscreen(false);
                display: flex;
                position: static;
                @include size(100%,auto);
                top: auto;
                left: auto;
                padding: 0 20px;
                .features-box {
                    padding: 0;
                    width: 50%;
                    text-align: left;
                    .feature-item {
                        display: block;
                        align-items: center;
                        margin: 80px auto 0;
                        padding: 20px 0;
                        flex-direction: row;
                        width: 80%;
                        max-width: auto;
                        &:nth-child(2) {
                            margin-right: auto;
                        }
                        .item-icon {
                            display: flex;
                            @include size(75px);
                            margin-bottom: 16px;
                            img {
                                @include size(32px);
                            }
                        }
                        .item-text {
                            margin-right: auto;
                            max-width: 100%;
                        }
                    }
                    &.--right {
                        padding: 0;
                        .feature-item {
                            padding: 20px 0;
                            .item-text {
                                margin: 0;
                            }
                            &:nth-child(2) {
                                margin-left: auto;
                                margin-right: auto;
                            }
                        }
                    }
                }
            }
        }
        .product-background {
            position: relative;
            @include size(100%,390px);
        }
        &__moreinfo {
            grid-column: 1/7;
            padding-bottom: 280px;
        }
    }
    @include xs {
        padding-top: 81px;
        &__logo {
            grid-column: 1/7;
            margin: 0 auto 32px;
        }
        &__title {
            grid-column: 1/7;
            margin-bottom: 20px;
            .--h2 {
                font-size: 4.2rem;
                line-height: 5rem;
            }
        }
        &__product {
            grid-column: 1/7;
            width: calc(100% + 40px);
            height: auto;        
            margin: 41px -20px 60px;
            position: relative;
            &-wrapper {
                .product-bottle {
                    &-inner {
                        width: 50%;
                        max-width: 155px;

                    }
                }
                .product-pills {
                    &-inner {
                        .pills-box,
                        .pills-box-right {
                            @include transform(scale3d(.6,.6,.6) translateY(5%));
                        }
                    }
                }
            }
            .product-features {
                @include fullscreen(false);
                display: block;
                position: static;
                @include size(100%,auto);
                top: auto;
                left: auto;
                .features-box {
                    text-align: center;
                    display: block;
                    padding: 0;
                    width: 100%;
                    .feature-item {
                        display: flex;
                        align-items: center;
                        margin: 0 auto;
                        padding: 31px 0;
                        flex-direction: column;
                        width: calc(100%-40px);
                        max-width: 279px;
                        &:nth-child(2) {
                            margin-right: auto;
                        }
                        .item-icon {
                            display: flex;
                            @include size(75px);
                            margin-bottom: 16px;
                            img {
                                @include size(32px);
                            }
                        }
                        .item-text {
                            margin-right: 0;
                            max-width: none;
                        }
                    }
                    &.--right {
                        text-align: center;
                        padding: 0;
                        .feature-item {
                            flex-direction: column;
                            .item-text {
                                margin-right: 0;
                                margin-left: 0;
                            }
                            &:nth-child(2) {
                                margin-right: auto;
                                margin-left: auto;
                            }
                        }
                    }
                }
            }
        }
        .product-background {
            position: relative;
            @include size(100%,339px);
            .bg-circle {
                @include size(70%);
            }
            .bg-blur {              
                .bg-blur-img {
                    min-width: 400%;
                    min-height: 400%;
                }
            }
        }
    }
}

#productanim {
    overflow: hidden;
}

.sctest {
    width: 100%;
    height: 80vh;
    background-color: wheat;
    .container {
        p {
            @include mid;
            grid-column: 1/13;
        }
    }
}

.scintrohome {
    background-color: rgba(0,0,0,.3);
    padding: 163px 0 122px;
    &__img {
        grid-column: 1/7;
        height: 100%;
        img {
            height: 685px;
            width: auto;
            margin: 175px 0 -280px 0;
        }
    }
    &__content {
        grid-column: 8/13;
        .content-title {
            .--h5 {
                margin-bottom: 14px;
                text-transform: uppercase;
            }
            .--h4 {
                color: $gold-cl;
                text-transform: uppercase;
                max-width: 70%;
                margin-bottom: 32px;
            }
        }
        .content-bodytext {
            @include col(2);
            column-gap: 32px;
            margin-bottom: 85px;
            .--text {
                color: $white60-cl;
            }
        }
    }
    @include lg {
        padding: 163px 0 122px;
        &__img {
            grid-column: 1/6;
            img {
                height: auto;
                width: 100%;
                margin: 70px 0 0 0;
                max-width: 100%;
            }
        }
        &__content {
            grid-column: 7/13;
            .content-title {
                .--h4 {
                    max-width: 100%;
                }
            }
        }
    }
    @include md {
        &__img {
            img {
                margin: 40% 0 0 0;
            }
        }
    }
    @include sm {
        padding: 0 0 114px;
        &__img {
            grid-column: 1/7;
            height: auto;
            img {
                height: auto;
                width: 64%;
                margin: -155px auto 80px;
                max-width: 100%;
            }
        }
        &__content {
            grid-column: 1/7;
            .content-title {
                width: calc(100% - 30px);
                margin-left: 30px;
                .--h4 {
                    max-width: calc(100%-40px);
                }
            }
            .content-bodytext {
                width: calc(100% - 30px);
                margin-left: 30px;
                @include col(1);
                column-gap: 0;
                margin-bottom: 44px;
            }
            .content-logo {
                width: calc(100% - 30px);
                margin-left: 30px;
            }
        }
    }
    @include xs {
        padding: 0 0 114px;
        &__img {
            grid-column: 1/7;
            height: auto;
            img {
                height: auto;
                width: 90%;
                margin: -155px auto 80px;
                max-width: 100%;
            }
        }
        &__content {
            grid-column: 1/7;
            .content-title {
                width: calc(100% - 30px);
                margin-left: 30px;
                .--h4 {
                    max-width: calc(100%-40px);
                }
            }
            .content-bodytext {
                width: calc(100% - 30px);
                margin-left: 30px;
                @include col(1);
                column-gap: 0;
                margin-bottom: 44px;
            }
            .content-logo {
                width: calc(100% - 30px);
                margin-left: 30px;
            }
        }
    }
}

.scfeaturehome {
    padding-top: 315px;
    &__title {
        grid-column: 2/12;
        text-align: center;
        .--h1 {
            color: $gold-cl;
            text-transform: uppercase;
            padding: 0 10%;
            margin-bottom: 90px;
        }
    }
    &__features {
        grid-column: 2/12;
        position: relative;
        .features-box {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .feature-item {
                width: 28%;
                .--h4 {
                    color: $gold-cl;
                    text-transform: uppercase;
                    margin-bottom: 32px;
                }
                .--text {
                    color: $white60-cl;
                }
                &:nth-child(2) {
                    margin-top: 162px;
                    .--h4 {
                        width: 80%;
                        min-width: 300px;
                    }
                }
                &:nth-child(3) {
                    margin-top: calc(162px * 2);
                }
            }
        }
        .features-cta {
            position: absolute;
            right: 189px;
            cursor: pointer;
            @include transition(.3s);
            .outer-circle {
                @include size(173px);
                border: solid 1px $gold50-cl;
                border-radius: 50%;
                display: inline-block;
                position: relative;
                @include transition($t);
                .cta-signup-text {
                    @include size(150px);
                    @include mid;
                    .anim-rotate {
                        @include animation(rotating 12s linear infinite);
                    }
                    @keyframes rotating {
                        from {
                            @include transform(rotate(0deg));
                        }
                        to {
                            @include transform(rotate(360deg));
                        }
                    }
                }
                .inner-circle {
                    @include size(102px);
                    border: solid 1px $gold50-cl;
                    border-radius: 50%;
                    @include mid;
                    @include mid(true);
                    @include transition($t);
                    .ic-arrows {
                        position: relative;
                        @include size(32px);
                        overflow: hidden;
                        .ic-arrow {
                            transform: translate3d(0%, 0%, 0);
                            @include transition(0s);
                            &.clone {
                                position: absolute;
                                top: 0;
                                left: 0;
                                transform: translate3d(-150%, 150%, 0);
                            }
                        }
                       
                    }
                }
                &:hover {
                    .ic-arrows {
                        .ic-arrow {
                            transform: translate3d(150%, -150%, 0);
                            @include transition(transform cubic-bezier(0.4, 0, 0.1, 1) 0.6s);
                            &.clone {
                                transform: translate3d(0%, 0%, 0);
                            }
                        }
                    }
                }
            }
            &:hover {
                @include transition(0.1s);
                .outer-circle {
                    border: solid 1px $gold-cl;
                    @include transition($t);
                    .inner-circle {
                        border: solid 1px $gold-cl;
                        @include transition($t);
                    }
                }
            }
        }
    }
    @include lg {
        padding-top: 220px;
    }
    @include md {
        padding-top: 200px;
        &__title {
            grid-column: 1/13;
        }
        &__features {
            grid-column: 1/13;
            .features-cta {
                position: absolute;
                right: calc(31% -32px);
                @include transform(translateX(100%));
            }
        }
    }
    @include sm {
        padding-top: 80px;
        &__title {
            grid-column: 1/7;
            .--h1 {
                font-size: 4.2rem;
                line-height: 5rem;
                padding: 0;
                margin-bottom: 48px;
            }
        }
        &__features {
            grid-column: 1/7;
            .features-box {
                width: 100%;
                display: block;
                .feature-item {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 48px;
                    .--h4 {
                        margin: 0 auto 20px;
                        font-size: 2rem;
                        line-height: 2.2rem;
                        max-width: 272px;
                    }
                    .--text {
                        max-width: 351px;
                        margin: 0 auto;
                    }
                    &:nth-child(2) {
                        margin-top: 0;
                        .--h4 {
                            width: 100%;
                            min-width: 272px;
                        }
                    }
                    &:nth-child(3) {
                        margin-top: 0;
                    }
                }
            }
            .features-cta {
                position: static;
                margin: 0 auto 48px;
                @include transform(none);
                cursor: pointer;
                .outer-circle {
                    display: block;
                    margin: 0 auto;
                    &:hover {
                        pointer-events: none;
                    }
                }
            }
        }
    }
    @include xs {
        padding-top: 80px;
        &__title {
            grid-column: 1/7;
            .--h1 {
                font-size: 4.2rem;
                line-height: 5rem;
                padding: 0;
                margin-bottom: 48px;
            }
        }
        &__features {
            grid-column: 1/7;
            .features-box {
                width: 100%;
                display: block;
                .feature-item {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 48px;
                    .--h4 {
                        margin: 0 auto 20px;
                        font-size: 2rem;
                        line-height: 2.2rem;
                        max-width: 272px;
                    }
                    .--text {
                        max-width: 351px;
                        margin: 0 auto;
                    }
                    &:nth-child(2) {
                        margin-top: 0;
                        .--h4 {
                            width: 100%;
                            min-width: 272px;
                        }
                    }
                    &:nth-child(3) {
                        margin-top: 0;
                    }
                }
            }
            .features-cta {
                position: static;
                margin: 0 auto 48px;
                @include transform(none);
                cursor: pointer;
                .outer-circle {
                    display: block;
                    margin: 0 auto;
                    &:hover {
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

.sctestimonialhome {
    padding: 203px 0 176px;
    &__title {
        grid-column: 2/6;
        grid-row: 1/2;
        margin-top: 116px;
        .--h2 {
            text-transform: uppercase;
            color: $gold-cl;
        }
    }
    &__content {
        grid-column: 5/12;
        margin-left: 7.6%;
        grid-row: 1/2;
        border: solid 1px $gold-cl;
        padding: 75px 143px 68px 44px;
        display: flex;
        position: relative;
        max-width: 100%;
        .line-mask {
            @include size (1px, calc(100% - 75px - 119px));
            position: absolute;
            margin-top: 75px;
            margin-bottom: 119px;
            top: 0;
            left: -1px;
            display: block;
            background-color: $background-cl;
        }
        .testimonial-quote {
            @include size(52px,100%);
            margin-right: 46px;
            img {
                @include size(52px,auto);
                max-width: 52px;
            }
        }
        .testimonial-slider {
            position: relative;
            float: left;
            overflow: hidden;
            z-index: 0;
        }
        .swiper-container {
            .swiper-wrapper {
                .swiper-slide {
                    padding-right: 20px;
                    .content-text {
                        p {
                            @include text(2.4rem,ti,3.8rem,$white40-cl,none);
                            margin-bottom: 38px;
                        }
                        border-bottom: solid 1px $white05-cl;
                        margin-bottom: 34px;
                        
                    }
                    .content-person {
                        .--text {
                            margin-bottom: 4px;
                        }
                        .--text-s {
                            color: $white50-cl;
                        }
                    }
                }
            }
        }
        .swiper-nav {
            position: absolute;
            display: flex;
            bottom: 60px;
            right: 143px;
            background-color: $background-cl;
            padding: 20px 0;
            z-index: 10;
            .nav-buttons {
                display: flex;
                justify-content: center;
                .swiper-button-prev,
                .swiper-button-next {
                    position: static;
                    transform: none;
                    margin-top: 0;
                    @include size(40px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: solid 1px $gold70-cl;
                    cursor: pointer;
                    &::after {
                        display: none;
                    }
                }
            }
            .swiper-pagination {
                position: static;
                transform: none;
                @include size(auto,40px);
                margin-right: 32px;
                text-align: right;
                @include mid(true);
                &-bullet {
                    @include size(8px);
                    background-color: $white20-cl;
                    opacity: 1;
                    margin: 0 0 0 12px;
                    display: inline-block;
                    border-radius: 50%;
                    &-active {
                        background-color: $gold-cl;
                    }
                }
            }
        }
    }
    @include md {
        padding: 120px 0 176px;
        &__title {
            grid-column: 2/10;
            margin-bottom: 80px;        
        }
        &__content {
            grid-column: 3/12;
            margin-left: 0;
            grid-row: 2/3;
            padding: 75px 100px 68px 44px;
            .line-mask {
                display: none;
            }
            .testimonial-slider {
                width: 100%;
            }
        }
    }
    @include sm {
        padding: 120px 0 176px;
        &__title {
            grid-column: 1/5;
            margin-bottom: 80px;
            .--h2 {
                font-size: 4.2rem;
                line-height: 5rem;
            }        
        }
        &__content {
            grid-column: 2/7;
            margin-left: 0;
            grid-row: 2/3;
            padding: 40px 60px 50px 40px;
            .line-mask {
                display: none;
            }
            .swiper-container {
                .swiper-wrapper {
                    .swiper-slide {
                        .content-text {
                            p {
                                @include text(2.0rem,ti,2.8rem,$white40-cl,none);
                                margin-bottom: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
    @include xs {
        padding: 120px 0 110px;
        &__title {
            grid-column: 1/7;
            grid-row: 1/2;
            margin-top: 0;
            margin-bottom: 48px;
            text-align: center;
            .--h2 {
                font-size: 4.2rem;
                line-height: 5rem;
            }
        }
        &__content {
            grid-column: 1/7;
            margin-left: 0;
            grid-row: 2/3;
            padding: 43px 17px 154px ;
            display: block;
            .line-mask {
                display: none;
            }
            .testimonial-quote {
                height: auto;
                margin: 0 auto 30px;
            }
            .testimonial-slider {
                position: relative;
                float: none;
                width: 100%;
                height: 100%;
                overflow: hidden;
            }
            .swiper-container {
                .swiper-wrapper {
                    .swiper-slide {
                        @include size(100%);
                        padding: 0 10px;
                        text-align: center;
                        .content-text {
                            margin-bottom: 30px;
                            p {
                                @include text(2.0rem,ti,2.8rem,$white40-cl,none);
                                margin-bottom: 43px;
                            }
                        }
                    }
                }
            }
            .swiper-nav {
                display: block;
                bottom: 54px;
                right: auto;
                padding: 0;
                left: 50%;
                @include transform(translateX(-50%));
                z-index: 1;
                .swiper-pagination {
                    height: 8px;
                    margin-right: 0px;
                    margin-bottom: 30px;
                    .swiper-pagination-bullet {
                        margin: 0 6px;
                    }
                }            
            }
        }
    }
}

.scbloghome {
    padding: 156px 0;
    background-color: rgba(0,0,0,.5);
    &__title {
        grid-column: 2/12;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60px;
        .--h3 {
            text-transform: uppercase;
            color: $gold-cl;
            br {
                display: none;
            }
        }
    }
    &__content {
        grid-column: 2/12;
        display: flex;
        justify-content: space-between;
    }
    .--mobile {
        display: none;
    }
    @include md {
        &__title {
            grid-column: 1/13;
        }
        &__content {
            grid-column: 1/13;
            @include col(3);
            column-gap: 32px;
        }
    }
    @include sm {
        padding: 80px 0 110px;
        &__title {
            grid-column: 1/7;
            display: block;
            margin-bottom: 48px;
            text-align: center;
            .--h3 {
                text-transform: uppercase;
                font-size: 4.2rem;
                line-height: 5rem;
            }
            .button {
                display: none;
            }
        }
        &__content {
            grid-column: 1/7;
            display: block;
        }
        .--mobile {
            grid-column: 2/6;
            display: block;
            text-align: center;
        }
    }
    @include xs {
        padding: 80px 0 110px;
        &__title {
            grid-column: 1/7;
            display: block;
            margin-bottom: 48px;
            .--h3 {
                text-transform: uppercase;
                font-size: 4.2rem;
                line-height: 5rem;
            }
            .button {
                display: none;
            }
        }
        &__content {
            grid-column: 1/7;
            display: block;
        }
        .--mobile {
            grid-column: 2/6;
            display: block;
            text-align: center;
        }
    }
}
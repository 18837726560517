.nav {
    display: none;
    
    @include md {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 29px;
        padding-bottom: 29px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0);
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
        @include transition($t);
        @include size (100vw,auto);
        &__logo {
            display: flex;
            align-items: center;
            @include size(auto, 20px);            
        }
        &__menu {
            position: relative;
            @include mid(true);
            .logo_lang {
                margin-right: 12px;
                z-index: 800;
                a {
                    @include text(1.2rem,is,1.8rem,$white30-cl,uppercase);
                }    
            }
            .nav__toggle {
                z-index: 800;
                .button {
                    padding: 0;
                    background-color: $background-cl;
                    span {
                        padding: 11px 12px;
                    }
                    .ic-block {
                        padding: 14px 12px 14px 12px;
                        border-left: solid 1px $gold-cl;
                        .draw-menu-ic {
                            @include size(15px, 13px);
                            position: relative;
                            @include transition($t);
                            span.ic-menu {
                                position: absolute;
                                top: .5px;
                                left: 0px;
                                @include size(15px, 1px);
                                display: block;
                                padding: 0;
                                background-color: $gold-cl;
                                @include transition($t);
                                &:nth-child(2) {
                                    width: 8px;
                                    top: 6px;
                                    left: 3.5px;
                                }
                                &:nth-child(3) {
                                    top: 12.5px;
                                }
                            }
                        }                        
                    }
                }
            }
            &-block {
                display: block;
                position: absolute;
                top: -29px;
                right: calc(-50vw - 40px);
                background-color: #000000;
                width: 50vw;
                min-width: 207px;
                max-width: 207px;
                height: auto;  
                padding-top: 95px;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 26px;
                @include transition($t);
                .menu {
                    position: relative;
                    margin-bottom: 40px;
                    margin-left: 32px;
                    li {
                        position: relative;
                        z-index: 2;
                        a {
                            display: block;
                            padding: 14px 0 14px 24px;
                            color: $white30-cl;
                            position: relative;
                            @include transition($t);
                            &::before {
                                content: "";
                                display: block;
                                @include size(16px);
                                border: solid 5px #000000;
                                @include transform(translateY(-50%) rotateZ(45deg));
                                background-color: $white30-cl;
                                position: absolute;
                                top: 50%;
                                left: -8px;
                                @include transition($t);
                            }
                            &:hover,
                            &.active {
                                color: $white-cl;
                                @include transition($t);
                                &::before {
                                    background-color: $gold-cl;
                                    @include transition($t);
                                }
                            }
                        }
                        span {
                            position: absolute;
                            left: -.5px;
                            top: 37px;
                            background-color: $white10-cl;
                            @include size (1px,35px);
                            display: block;
                        }
                    }
                    &.--blogs {
                        li {
                            &:nth-child(1) {
                                span {
                                    height: 60px;
                                }
                            }
                            &:nth-child(2) {
                                span {
                                    height: 47px;
                                }
                            }
                            &:nth-child(3) {
                                span {
                                    height: 72px;
                                }
                            }
                        }
                    }
                }
                .button.buynow {
                    padding: 10px 0;
                    text-align: center;
                    display: block;
                }
            }
        }
        &.active {
            .nav__menu {
                .nav__toggle {
                    .ic-block {
                        .draw-menu-ic {
                            @include transform(translateY(-1px));
                            @include transition($t);
                            span.ic-menu {
                                position: absolute;
                                top: .5px;
                                left: 0px;
                                @include size(19px, 1px);
                                display: block;
                                padding: 0;
                                background-color: $gold-cl;
                                @include transform(rotateZ(45deg));
                                transform-origin: 0% 50%;
                                @include transition($t);
                                &:nth-child(2) {
                                    width: 0px;
                                }
                                &:nth-child(3) {
                                    @include transform(rotateZ(-45deg) translateX(-9.5px) translateY(9.5px));
                                    transform-origin: 0% 50%;
                                }
                            }
                        }                        
                    }
                }
                .nav__menu-block {
                    right: -20px;
                    @include transition($t);
                }
            }
        }
        &.scroll {
            padding-top: 20px;
            padding-bottom: 20px;
            background-color: rgba(0,0,0,.3);
            -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
            @include transition($t);
        }
    }
}


// css loading

.page-loading {
    @include fullscreen(true);
    background-color: #131313;
    z-index: 999;
    opacity: 1;
    pointer-events: auto;
    &__logo {
        @include mid;
        opacity: 0;
        text-align: center;
        z-index: 1;
        img {
            margin-bottom: 32px;
        }
    }
    &__text {
        @include mid;
        text-align: center;
        text-transform: uppercase;
        opacity: 0;
        .--h5 {
            margin-bottom: 18px;
            color: $white60-cl;
        }
        .--h2 {
            color: $gold-cl;
        }
    }
    &.is-loaded {
        pointer-events: none;
        opacity: 0;
    }
    @include lg {
        &__text {
            .--h2 {
                br {
                    display: none;
                }
            }
        }
    }
    @include sm {
        &__text {
            @include mid;
            text-align: center;
            text-transform: uppercase;
            opacity: 0;
            width: 100%;
            padding: 0 40px;
            .--h2 {
                font-size: 3.4rem;
                line-height: 4rem;
                br {
                    display: none;
                }
            }
        }
    }
}
@font-face {
    font-family: "i";
    src: url("../fonts/Inter-Regular.woff2") format("woff2"), url("../fonts/Inter-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: "il";
    src: url("../fonts/Inter-Light.woff2") format("woff2"), url("../fonts/Inter-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: "is";
    src: url("../fonts/Inter-Semibold.woff2") format("woff2"), url("../fonts/Inter-Semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: "ib";
    src: url("../fonts/Inter-Bold.woff2") format("woff2"), url("../fonts/Inter-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: "ie";
    src: url("../fonts/Inter-Extrabold.woff2") format("woff2"), url("../fonts/Inter-Extrabold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: "c";
    src: url("../fonts/Cormorant-Regular.woff2") format("woff2"), url("../fonts/Cormorant-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: "cm";
    src: url("../fonts/Cormorant-Medium.woff2") format("woff2"), url("../fonts/Cormorant-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: "cs";
    src: url("../fonts/Cormorant-Semibold.woff2") format("woff2"), url("../fonts/Cormorant-Semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "ti";
    src: url("../fonts/Times-Italic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: fallback;
}



// css components
.button {
    display: flex;
    align-items: center;
    @include text(1.2rem,is,1.8rem,$gold-cl,uppercase);
    letter-spacing: .1em;
    padding: 10px 18px 10px 20px;
    border: solid 1px $gold70-cl;
    cursor: pointer;
    position: relative;
    img {
        float: left;
        margin-right: 8px;
    }
    &.--big {
        padding: 18px 14px 18px 20px;
    }
}

.--hyperion::before,
.--hyperion::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.--hyperion {
	border: solid 1px $gold70-cl;
	overflow: hidden;
    background-color: $gold-cl;
}

.--hyperion span {
	display: flex;
	position: relative;
}

.--hyperion > span {
	overflow: hidden;
}

.--hyperion > span > span {
	overflow: hidden;
	mix-blend-mode: difference;
}

.--hyperion:hover > span > span {
	animation: MoveUpInitial 0.2s forwards, MoveUpEnd 0.2s forwards 0.2s;
}
.--hyperion:hover > span > span:nth-child(2) {
	animation: MoveUpInitial 0.3s forwards, MoveUpEnd 0.3s forwards 0.2s;
}


@keyframes MoveUpInitial {
	to {
		transform: translate3d(0,-105%,0);
	}
}

@keyframes MoveUpEnd {
	from {
		transform: translate3d(0,100%,0);
	}
	to {
		transform: translate3d(0,0,0);
	}
}

.--hyperion::before {
	content: '';
	//background-color: $gold-cl;
    background-color: $background-cl;
	transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
	transform-origin: 100% 50%;
}

.--hyperion:hover::before {
	transform: scale3d(0,1,1);
	transform-origin: 0% 50%;
}

// Sound-icon
.sound-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.sound-wave {
    width: 32px;
    height: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .bar {
        display: block;
        width: 1px;
        border-radius: 10px;
        margin-right: 3px;
        height: 20px;
        background: $white60-cl;
        @include transition($t);
        animation: sound 0ms -780ms linear infinite alternate;
        transition: height 1s;
    }
}
  
@keyframes sound {
    0% {
        height: 2px; 
    }
    100% {  
        height: 20px;
    }
}

.bar:nth-child(6)  { height: 2px; animation-duration: 580ms; }
.bar:nth-child(1)  { height: 4px; animation-duration: 600ms; }
.bar:nth-child(4)  { height: 8px; animation-duration: 620ms; }
.bar:nth-child(3)  { height: 12px; animation-duration: 640ms; }
.bar:nth-child(5)  { height: 16px; animation-duration: 660ms; }
.bar:nth-child(2)  { height: 20px; animation-duration: 660ms; }

.sound-icon.is-muted {
    .sound-wave {
        .bar {
            animation-fill-mode: backwards;
            animation-iteration-count: 1;
            background: $white40-cl;
            @include transition($t);
        }
    }
}
.sound-icon:hover {
    .sound-wave {
        .bar {
            background: $white-cl;
            @include transition($t);
        }
    }    
}

// Blog-item

.blog-item {
    width: 28%;
    display: block;
    .blog-img {
        height: 254px;
        position: relative;
        margin-bottom: 16px;
        overflow: hidden;
        img {
            @include midimg;
            opacity: .2;
            @include transition($t);  
            filter: saturate(70%);
            -webkit-filter: saturate(100%);                  
        }
        .overlay {
            @include overlay(0);
            background-color: #393121;
            mix-blend-mode: overlay;
            @include transition($t);  
        }
        .item-line {
            @include overlay(10px);
            border: solid 1px $gold50-cl;
        }
    }
    .blog-title {
        color: $gold-cl;
        margin-bottom: 16px;
        @include transition($t);
    }
    a:hover {
        .blog-img {
            img {
                opacity: 1;
                filter: saturate(100%);
                -webkit-filter: saturate(100%);
                @include transition($t);
            }
            .overlay {
                background-color: #93815D;
                @include transition($t);  
            }
        }
        .blog-title {
            color: $white-cl;
            @include transition($t);
        }
    }
    .blog-info {
        display: flex;
        justify-content: space-between;
        padding: 16px 0;
        border-top: solid 1px $gold30-cl;
        border-bottom: solid 1px $gold30-cl;
        .info-block {
            width: 30%;
            display: flex;
            align-items: center;
            .--text-s {
                margin-left: 8px;
                color: $white40-cl;
                @include transition($t);
            }
            &:hover {
                .--text-s {
                    color: $white-cl;
                    @include transition($t);
                }
            }
        }
    }
    @include md {
        width: 100%;
        .blog-img {
            height: 220px;
        }
    }
    @include sm {
        width: 100%;
        margin-bottom: 48px;
        .blog-img {
            .overlay {
                background-color: #d5ba84;
            }
            &:hover {
                pointer-events: none;
            }
        }
    }
    @include xs {
        width: 100%;
        margin-bottom: 48px;
        .blog-img {
            &:hover {
                pointer-events: none;
            }
        }        
    }
}

// .--reverse {
//     background-color: $background-cl;
// }

// .--reverse::before {
//     background-color: $gold-cl;
// }

// Pop-up About Information

.popup-info {
    max-width: 586px;
    background-color: #0a0a0a;
    padding: 20px;
    position: fixed;
    left: 21px;
    bottom: 23px;
    z-index: 800;
    pointer-events: none;
    opacity: 0;
    @include transition($t);
    @include transform(translateY(-25px));
    .overlay-popup {
        display: none;
    }
    .popup-wrapper {
        @include size(100%);
        padding: 32px 29px 32px 29px;
        border: solid 1px $gold40-cl;
        position: relative;
        .ic-close {
            @include size(40px);
            @include mid(true);
            border: solid 1px $gold40-cl;
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            @include transition($t);
                &:hover {
                    border: solid 1px $gold-cl;
                    @include transition($t);
                }
        }
        .--h4 {
            text-transform: uppercase;
            margin-bottom: 23px;
            color: $white90-cl;
        }
        .popup-info__content {
            width: 100%;
            height: auto;
            max-height: 249px;
            overflow: scroll;
            .--text {
                height: 100%;
                color: $white60-cl;
                overflow: auto;
            }
        }

    }
    &.active {
        pointer-events: auto;
        opacity: 1;
        @include transition($t);
        @include transform(translateY(0px));
    }
    @include sm {
        max-width: calc(100% - 40px);
        background-color: #0a0a0a;
        padding: 20px;
        position: fixed;
        left: 20px;
        right: 20px;
        bottom: 42px;
        top: 42px;
        .overlay-popup {
            display: block;
            opacity: 1;
            pointer-events: none;
            position: absolute;
            top: -42px;
            left: -20px;
            right: -20px;
            bottom: -42px;
            border-top: solid 42px $black90-cl;
            border-bottom: solid 42px $black90-cl;
            border-left: solid 20px $black90-cl;
            border-right: solid 20px $black90-cl;
        }
        .popup-wrapper {
            @include size(100%);
            padding: 32px 26px 49px 26px;
            border: solid 1px $gold40-cl;
            position: relative;
            .--h4 {
                width: 90%;
            }
            .popup-info__content {
                height: calc(100% - 100px);
                max-height: none;
                .--text {
                    overflow: scroll;
                }
            }
        }
        &#about-3 {
            .popup-wrapper {
                .popup-info__content {
                    height: calc(100% - 149px);
                }
            }
        }
        &.active {
            touch-action: none;
        }
    }
}

// Pop-up SignUp Information

.popup-signup {
    @include size(100vw, 100vh);
    background-color: $black90-cl;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 900;
    pointer-events: none;
    opacity: 0;
    @include transition($t);
    &__wrapper {
        pointer-events: none;
        opacity: 0;
        @include transition($t);
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translateX(-50%) translateY(-50%));
        width: 100%;
        max-width: 626px;
        height: auto;
        padding: 20px; 
        background-color: #0a0a0a;       
        .poup-signup__content {
            border: solid 1px $gold40-cl;
            width: 100%;
            position: relative;
            padding: 40px 32px 60px;
            .ic-close {
                @include size(40px);
                @include mid(true);
                border: solid 1px $gold40-cl;
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
                @include transition($t);
                &:hover {
                    border: solid 1px $gold-cl;
                    @include transition($t);
                }
            }
            .--h4 {
                text-transform: uppercase;
                margin-bottom: 23px;
                color: $white90-cl;
                width: 90%;
                margin-bottom: 27px;
            }
            .form__input {
                input,
                textarea {
                    width: 100%;
                    height: 52px;
                    border: solid 1px $white10-cl;
                    background-color: transparent;
                    margin-bottom: 20px;
                    outline: none;
                    @include text(1.4rem,i,2.2rem,$gold90-cl,none);
                    padding-left: 40px;
                    background: url('../img/ic-author.svg') no-repeat 12px 18px;
                    @include transition($t);
                    &::placeholder {
                        color: $gold60-cl;
                        opacity: 1;
                        @include transition($t);
                    }
                    &:focus {
                        color: $gold-cl;
                        border: solid 1px $gold90-cl;
                        @include transition($t);
                    }
                    &:disabled {
                        color: $gold20-cl;
                        border: solid 1px $white05-cl;
                        @include transition($t);
                    }
                    &:invalid {
                        color: $gold-cl;
                        border: solid 1px $gold-cl;
                        position: relative;
                        @include transition($t);
                        &::after {
                            content: "Error message";
                            @include text(1.2rem,i,2rem,#E74545,none);
                            display: inline-block;
                            right: 0;
                            bottom: 25px;
                        }
                    }
                }
                &.--form-phone {
                    input {
                        background: url('../img/ic-phone-call.svg') no-repeat 12px 18px;;
                    }
                }
                textarea {
                    height: 110px;
                    margin-bottom: 30px;
                    background: url('../img/ic-mail.svg') no-repeat 12px 18px;
                    padding-top: 15px;
                    @include transition($t);
                }
            }
            .form__submit {
                display: flex;
                justify-content: space-between;
                .form-hotline {
                    display: flex;
                    align-items: center;
                    .hotline-text {
                        @include text(1.2rem,is,1.8rem,$white30-cl,uppercase);
                        letter-spacing: .12rem;
                        margin-left: 10px;
                        span {
                            font-family: ie;
                            color: $gold-cl;
                        }
                    }
                }
                button {
                    outline: 0;
                    border: none;
                    padding: 19px 58px;
                    background-color: $gold-cl;
                    @include text(1.2rem,is,1.8rem,$white90-cl,uppercase);
                    letter-spacing: .12rem;
                    position: relative;
                    cursor: pointer;
                }
                button::before,
                button::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                button {
                    border: 1px solid $gold-cl;
                    overflow: hidden;
                    color: $white-cl;
                    background-color: $background-cl;
                }
                
                button span {
                    display: block;
                    position: relative;
                }
                
                button > span {
                    overflow: hidden;
                }
                
                button > span > span {
                    overflow: hidden;
                    //mix-blend-mode: difference;
                }
                
                button:hover > span > span {
                    color: $gold-cl;
                    animation: MoveUpInitial 0.2s forwards, MoveUpEnd 0.2s forwards 0.2s;
                }
                
                @keyframes MoveUpInitial {
                    to {
                        transform: translate3d(0,-105%,0);
                    }
                }
                
                @keyframes MoveUpEnd {
                    from {
                        transform: translate3d(0,100%,0);
                    }
                    to {
                        transform: translate3d(0,0,0);
                    }
                }
                
                button::before {
                    content: '';
                    background: $gold-cl;
                    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
                    transform-origin: 100% 50%;
                }
                
                button:hover::before {
                    transform: scale3d(0,1,1);
                    transform-origin: 0% 50%;
                }
            }
        }
        &.active {
            pointer-events: auto;
            opacity: 1;
            @include transition($t);
            //touch-action: none;
        }
    }
    &.active {
        opacity: 1;
        pointer-events: auto;
        @include transition($t);
        touch-action: none;
    }
    @include xs {
        &__wrapper {
            @include size(calc(100vw - 40px), auto);
            top: 50%;
            left: 20px;
            right: 20px;
            @include transform(translateY(-50%));
            .poup-signup__content {
                padding: 32px 26px 49px;
                .form__input {
                    textarea {
                        margin-bottom: 20px;
                    }
                }
                .form__submit {
                    flex-direction: column-reverse;
                    align-items: center;
                    button {
                        width: 100%;
                        margin-bottom: 22px;
                    }
                }
            }
        }
    }
    &.--success {
        opacity: 0;
        pointer-events: none;
        @include transition($t);
        background-color: transparent;
        .popup-signup__wrapper {
            .poup-signup__content {
                text-align: center;
                .--h4 {
                    margin-top: 82px;
                    width: 100%;
                    margin-bottom: 16px;
                }
                .popup-decor {
                    margin: 0 auto 20px;
                }
                .--text.--success {
                    padding: 0 52px;
                    margin: 0 auto 50px;
                    color: $white60-cl;
                }
                button {
                    outline: 0;
                    border: none;
                    padding: 19px 58px;
                    background-color: $gold-cl;
                    @include text(1.2rem,is,1.8rem,$white90-cl,uppercase);
                    letter-spacing: .12rem;
                    position: relative;
                    cursor: pointer;
                }
                button::before,
                button::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                button {
                    border: 1px solid $gold-cl;
                    overflow: hidden;
                    color: $white-cl;
                    background-color: $background-cl;
                }
                
                button span {
                    display: block;
                    position: relative;
                }
                
                button > span {
                    overflow: hidden;
                }
                
                button > span > span {
                    overflow: hidden;
                    //mix-blend-mode: difference;
                }
                
                button:hover > span > span {
                    color: $gold-cl;
                    animation: MoveUpInitial 0.2s forwards, MoveUpEnd 0.2s forwards 0.2s;
                }
                
                @keyframes MoveUpInitial {
                    to {
                        transform: translate3d(0,-105%,0);
                    }
                }
                
                @keyframes MoveUpEnd {
                    from {
                        transform: translate3d(0,100%,0);
                    }
                    to {
                        transform: translate3d(0,0,0);
                    }
                }
                
                button::before {
                    content: '';
                    background: $gold-cl;
                    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
                    transform-origin: 100% 50%;
                }
                
                button:hover::before {
                    transform: scale3d(0,1,1);
                    transform-origin: 0% 50%;
                }
            }
        }
        &.active {
            opacity: 1;
            pointer-events: auto;
            @include transition($t);
        }
        @include xs {
            .popup-signup__wrapper {
                @include size(calc(100vw - 40px), auto);
                top: 50%;
                left: 20px;
                right: 20px;
                @include transform(translateY(-50%));
                .poup-signup__content {
                    padding: 32px 26px 49px;
                    .--h4 {
                        margin-top: 32px;
                    }
                    .--text.--success {
                        padding: 0;
                        margin: 0 auto 50px;
                        color: $white60-cl;
                    }
                    button {
                        margin-bottom: 14px;
                    }
                }
            }
        }
    }
}

// Pop-up Product Information

.popup-product-info {
    @include size(100vw, 100vh);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 900;
    opacity: 0;
    pointer-events: none;
    @include transition($t);
    .product-info__overlay {
        @include size(100%);
        background-color: $black90-cl;
        position: absolute;
        top: 0;
        left: 0;
    }
    .product-info__slide {
        @include size(100%);
        max-width: 960px;
        background-color: #0A0A0A;
        position: absolute;
        top: 0;
        right: 0;
        padding: 20px 20px 32px 20px;
        @include transform(translateX(100%));
        opacity: 0;
        @include transition($t);
        touch-action: none;
        .product-info__wrapper {
            @include size(100%);
            border: solid 1px $gold40-cl;
            position: relative;
            .ic-close {
                @include size(40px);
                @include mid(true);
                border: solid 1px $gold40-cl;
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
                @include transition($t);
                    &:hover {
                        border: solid 1px $gold-cl;
                        @include transition($t);
                    }
            }
            .--h4 {
                margin: 40px 0 39px 60px;
                text-transform: uppercase;
                color: $white90-cl;
                br {
                    display: none;
                }
            }
            .product-info__content {
                @include size(100%, auto);
                padding: 0 40px 0 60px;
                margin-bottom: 60px;
                overflow-y: scroll;
                height: calc(100% - 183px);
                .--h5 {
                    color: $gold-cl;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                }
                .--text {
                    color: $white60-cl;
                    margin-bottom: 26px;
                    &.--last {
                        margin-bottom: 32px;
                    }
                }
                .content-ingredient {
                    display: flex;
                    margin-bottom: 32px;
                    .ingre-img-percent {
                        margin-right: 9px;
                    }
                    .ingredient {
                        margin-left: 15px;
                        padding-top: 28px;
                        .ingredient-item {
                            display: flex;                            
                            margin-bottom: 25px;
                            .--text {
                                color: $white90-cl;
                                margin-bottom: 0;
                            }
                            .--gold {
                                color: $gold-cl;
                            }
                        }
                    }
                }
                .content-ingre-list {
                    margin-bottom: 32px;
                    .ingre-list-item {
                        display: flex;
                        margin-bottom: 16px;
                        .list-item-title {
                            display: flex;
                            .ic-check {
                                @include size(16px, 26px)
                            }
                            .--text.bold {
                                margin-left: 14px;
                                margin-bottom: 0;
                                min-width: 225px;
                                color: $white90-cl
                            }
                        }
                        .--text {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    &.active {
        opacity: 1;
        pointer-events: auto;
        @include transition($t);
        .product-info__slide {
            @include transform(none);
            opacity: 1;
            @include transition($t);
        }
    }

    @include sm {
        .product-info__slide {
            max-width: 100%;
        }
    }
    @include xs {
        .product-info__slide {
            .product-info__wrapper {
                .--h4 {
                    margin: 32px 40px 27px 30px;
                    br {
                        display: inline-block;
                    }
                }
                .product-info__content {
                    padding: 0 40px 0 30px;
                    margin-bottom: 12px;
                    height: calc(100% - 158px);
                    .content-ingredient {
                        align-items: center;
                        .ingre-img-percent {
                            margin-right: 23px;
                        }
                        .ingre-img-arrows {
                            display: none;
                        }
                        .ingredient {
                            margin-left: 0px;
                            padding-top: 0px;
                            .ingredient-item {
                                display: block;                            
                                margin-bottom: 32px;
                                .--text {
                                    font-size: 1.2rem;
                                    line-height: 2rem;
                                }
                                &:nth-child(1) {
                                    margin-bottom: 12px;
                                }
                                &:nth-child(4) {
                                    margin-bottom: 0px;
                                }
                            }
                        }
                    }
                    .content-ingre-list {
                        .ingre-list-item {
                            display: block;
                            .--text {
                                margin-left: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 406px) {
        .product-info__slide {
            .product-info__wrapper {
                .product-info__content {
                    .content-ingredient {
                        .ingredient {
                            .ingredient-item {
                                &:nth-child(3) {
                                    margin-bottom: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.scheroblogs {
    padding-top: $pd-top ;
    &__title {
        grid-column: 2/12;
        text-align: center;
        .logo {
            display: inline-block;
            margin: 4px 0 46px;
        }
        &-inner {
            .sub-title {
                @include text(1.6rem,cm,1.9rem,$white-cl,uppercase);
                letter-spacing: .6em;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 32px;
                span {
                    @include size(60px,1px);
                    background-color: #DBC283;
                    opacity: .3;
                    margin: 0 37px;
                    display: inline-block;
                }
            }
            .heading {
                font-family: c;
                text-transform: uppercase;
                color: $gold-cl;
                margin-bottom: 128px;
            }
        }
    }
    @include md {
        &__title {
            grid-column: 1/13;
        }
    }
    @include sm {
        padding-top: 140px;
        &__title {
            grid-column: 1/7;
            .logo {
                margin: 0 0 29px;
            }
            &-inner {
                .sub-title {
                    margin-bottom: 29px;
                    span {
                        @include size(60px,1px);
                        background-color: #DBC283;
                        opacity: .3;
                        margin: 0 36px 0 0;
                        display: inline-block;
                        &:nth-child(2) {
                            margin: 0 0 0 36px;
                        }
                    }
                }
                .heading {
                    font-size: 4.2rem;
                    line-height: 4.8rem;
                    margin-bottom: 43px;
                }
            }
        }
    }
}

.sclistblogs {
    &__content {
        grid-column: 3/11;
        border-bottom: solid 1px $gold30-cl;
        .blog-item-large {
            margin-bottom: 90px;
            a {
                .blog-img {
                    margin-bottom: 48px;
                }
                .--h4 {
                    margin: 0 auto 32px;
                    color: $gold-cl;
                    text-transform: uppercase;
                    width: 75%;
                    text-align: center;
                    @include transition($t);
                }
                &:hover {
                    .--h4 {
                        color: $white80-cl;
                        @include transition($t);
                    }
                }
            }
            .blog-info {
                margin: 0 auto;
                max-width: 352px;
                display: flex;
                justify-content: space-between;
                padding: 16px 0;
                border-top: solid 1px $gold30-cl;
                border-bottom: solid 1px $gold30-cl;
                .info-block {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    .--text-s {
                        margin-left: 8px;
                        color: $white40-cl;
                        @include transition($t);
                    }
                    &:hover {
                        .--text-s {
                            color: $white-cl;
                            @include transition($t);
                        }
                    }
                }
            }
        }
    }
    @include sm {
        &__content {
            grid-column: 2/6;
            .blog-item-large {
                a {
                    .blog-img {
                        margin-bottom: 32px;
                    }
                    .--h4 {
                        margin: 0 auto 24px;
                        font-size: 3.2rem;
                        line-height: 4.2rem;
                        width: 100%;
                    }
                }
            }
        }
    }
    @include xs {
        &__content {
            grid-column: 1/7;
        }
    }
}

.scpaginationblogs {
    padding: 80px 0;
    .pagi_wrapper {
        grid-column: 3/11;
        @include mid(true);
        .pagi_prev,
        .pagi_next {
            @include size(40px);
            border: solid 1px $gold20-cl;
            @include mid(true);
            @include transition($t);
            cursor: pointer;
            img {
                opacity: .5;
                @include transition($t);
            }
            &:hover {
                border: solid 1px $gold50-cl;
                @include transition($t);
                img {
                    opacity: 1;
                    @include transition($t);
                }
            }
        }
        .pagi_prev {
            margin-right: 14px;
        }
        .pagi_next {
            margin-left: 14px;
        }
        .pagi-item {
            @include size(40px);
            border: solid 1px $gold20-cl;
            @include mid(true);
            @include transition($t);
            cursor: pointer;
            margin: 0 4px;
            .--text {
                color: $gold50-cl;
                @include transition($t);
            }
            &:hover,
            &.active {
                border: solid 1px $gold50-cl;
                @include transition($t);
                .--text {
                    color: $white-cl;
                    @include transition($t);
                }
            }
        }
    }
    @include sm {
        .pagi_wrapper {
            grid-column: 2/6;
        }
    }
    @include xs {
        .pagi_wrapper {
            grid-column: 1/7;
            .pagi-item:nth-child(6) {
                display: none;
            }
        }
    }
}